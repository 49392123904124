import Vue from 'vue';
import { ActionTree } from 'vuex';
import download from "downloadjs";

import { RootState } from '@/store/root.interface';
import { TOAST_OPTIONS } from '@/constants';
import {
  REVOKE_REASON_TYPE_IDS,
  REVOKE_IDENTIFICATION_TYPE_IDS,
} from '@/constants/revoke.constants';

import api from './revoke-list.api';
import { IRevokeListState, TGetCertificatesByFilterSearchBy } from './revoke-list.types';
import {
  SET_REVOKE_LIST,
  SET_TOTAL_PAGES,
  SET_ACTIVE_PAGE,
  SET_SIZE_PAGES,
  SET_IS_LOADED
} from './revoke-list.mutations';

export const actions: ActionTree<IRevokeListState, RootState> = {
  async getList({commit, state, rootGetters}) {
    const queryParams = rootGetters["filterState/getSearchString"]("revokes")
                      + rootGetters["filterState/getFilterString"]("revokes");

    const { data, error } = await api.getList({
      sizePage: state.sizePage,
      activePage: state.activePage,
      queryParams,
    });

    if (error || !data) {
      Vue.$toast.error(
        `Ошибка при получении списка заявок на отзыв: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return;
    }

    commit(SET_REVOKE_LIST, data.applications);
    commit(SET_TOTAL_PAGES, data.totalPages);
    if (data.currentPage !== state.activePage) {
      commit(SET_ACTIVE_PAGE, data.currentPage !== 0 ? data.currentPage : 1);
    }
    commit(SET_IS_LOADED, true);
  },

  async getReport({ rootGetters }) {
    const queryParams = rootGetters["filterState/getSearchString"]("revokes")
                      + rootGetters["filterState/getFilterString"]('revokes');

    const { data, headers, error } = await api.getReport({queryParams});

    if (error || !data || !headers) {
      Vue.$toast.error(
        `Ошибка выгрузки CSV заявок на отзыв: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return;
    }

    let fileName;
    try { 
      fileName = decodeURI(headers["content-disposition"].match(/filename=(.*);/)[1]);
    } catch {
      const now = new Date(Date.now());
      fileName = `Список_заявок_на_отзыв_${now.toLocaleDateString()}_${now.toLocaleTimeString()}.csv`;
    }
  
    download(data, fileName, headers["content-type"]);
  },

  async getCertificatesByFilter(_, {searchBy, search}: {searchBy: TGetCertificatesByFilterSearchBy, search: string}) {
    const { data, error } = await api.getCertificatesByFilter({searchBy, search});

    if (error || !data) {
      Vue.$toast.error(
        `Ошибка при получении списка сертификатов заявителя: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return [];
    }

    if (!data.certificates || !data.certificates.length) {
      Vue.$toast.error(
        `Сертификаты по запросу ${searchBy}=${search} не найдены`,
        TOAST_OPTIONS.Error
      );
      return [];
    }

    return data.certificates;
  },

  async createNewRevoke(_, {
    identificationType,
    reason,
    serialNumber,
    phone,
    thumbprint,
  }: {
    identificationType: REVOKE_IDENTIFICATION_TYPE_IDS;
    reason: REVOKE_REASON_TYPE_IDS;
    serialNumber: string;
    phone: string;
    thumbprint: string;
  }) {
    const { data, error } = await api.createNewRevoke({
      identificationType,
      reason,
      serialNumber,
      phone,
      thumbprint,
    });

    if (error || !data) {
      Vue.$toast.error(
        `Ошибка при создании новой заявки на отзыв сертификата: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return null;
    }

    if (!data.number) {
      Vue.$toast.error(
        `Заявка на отзыв для сертификата ${serialNumber} не создана: orderId не получен`,
        TOAST_OPTIONS.Error
      );
      return null;
    }

    return data.number;
  },

  changePage({ commit, state, dispatch }, page: number) {
    if (state.activePage !== page) {
      commit(SET_ACTIVE_PAGE, page);
      dispatch("getList");
    }
  },

  changeSizePage({ commit, state, dispatch }, sizePage: number) {
    if (state.sizePage !== sizePage) {
      commit(SET_SIZE_PAGES, sizePage);
      dispatch("getList");
    }
  },

  setActivePage({ commit }, activePage: number) {
    commit(SET_ACTIVE_PAGE, activePage);
  },

  async getFilialByManager({}, managerId: number) {
    const { data, error } = await api.getFilialByManager(managerId);

    if (error || !data) {
      Vue.$toast.error(
        `Информация о филиале по managerId ${managerId} не получена: ${error || 'данные не получены'}`,
        TOAST_OPTIONS.Error
      );
      return null;
    }

    return data;
  },
};
